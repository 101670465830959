import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiButton } from '@elastic/eui';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "S-Mount Lenses",
  "path": "/Products/Lenses/S-Mount/IN-1600/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "INSTAR IP Camera Lense Upgrades",
  "image": "../../../P_SearchThumb_Products.png",
  "social": "/images/Search/P_SearchThumb_Products.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Lenses_white.webp",
  "chapter": "Products"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='IN-1600 S-Mount Lenses' dateChanged='2017-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='INSTAR IP Camera Lense Upgrades' image='/images/Search/P_SearchThumb_Products.png' twitter='/images/Search/P_SearchThumb_Products.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Produkte/Lenses/S-Mount/Lense_Comparison/IN-1600/' locationFR='/fr/Products/Lenses/S-Mount/Lense_Comparison/IN-1600/' crumbLabel="IN-1600" mdxType="BreadCrumbs" />
    <h2 {...{
      "id": "in-1600-tele",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#in-1600-tele",
        "aria-label": "in 1600 tele permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-1600 Tele`}</h2>
    <p><strong parentName="p">{`Compatible Camera Models`}</strong></p>
    <ul>
      <li parentName="ul">{`IN-3001 Indoor Camera`}</li>
      <li parentName="ul">{`IN-3003 Indoor Camera`}</li>
      <li parentName="ul">{`IN-3005 Indoor Camera`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Indoor_Cameras/IN-3011/"
        }}>{`IN-3010/1 Indoor Camera`}</a></li>
      <li parentName="ul">{`IN-2901 Outdoor Camera`}</li>
      <li parentName="ul">{`IN-2904 Outdoor Camera`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Outdoor_Cameras/IN-2905_V2/"
        }}>{`IN-2905 V2 Outdoor Camera`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Indoor_Cameras/IN-6012_HD/"
        }}>{`IN-6011/2 HD Indoor Camera`}</a></li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "642px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/79a3d7c3a1b3ca49434b9eaaa9eba99b/1bba8/Lense_16mm_Angle_of_View.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "74.78260869565217%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAC80lEQVQ4y1WTe4/aRhTF/eWrtlJVpUqbVVMpkbLZpqHtbtKku+HhMRhsYNngB+A3BvwCkvTxDX6VB3a7+ePoXs/MPfecO2Olc91Fnei0xxrqpCfRGQrESKPzXqctmrReX9B88xpVbyPPX/dkFBOdgWHQ7fbw/YBG41cUEZqI6Ijw/6g5GiIeok67NM9f0rp6heb20PwBIhqiRia9aMzN1MKyXLa7PctliqJ6BsI3UI+QeWAi5jqa3UUNh4j5AOGbCG+A5nQRgYkaDNDcMc3mjNk8Zrvb4bozFCFJzAPqg7fkgYlma4h5HzU0D00WA7puT+ZqYOBGATeTiCheUW13LDwfpeMbtP0+be8ALRrSjUZo4ZBuOESzBJ27Jgaqo6F6A2argKIoKcqCvCgoqy3zhYcymo2x0wU3ics4trgcd3h73ebKEvwxaaF7BoNabTRCBEPGizHRKqEot2yynCwvyfJbwgVKElqURcW22uP5AZeXV7z985Jvv3vAF199ydcPvuHFi2e8E02iVUqRrqnyktnCwxyOSVdrcql0i1dbjiJbdqh2OwaGKfNlukLX+1xcvOLxjz9x3viNi59/IU5SirygSFfM5h6GObojlArnC5Q4dqXs3X5PT9fpDwxUoXH6/DknJyecnZ3R6nTottvEQSjnli9TsrJkvckk2a1lz/dRkmROXlRyY7Xe0NP7/H5+zg+PHvHs9JSHD7/nZaPBu2aLdRCS5wXZMj3Or/iMUCpcLj3ycnu3uNt/4MPHT/z9z798/PSX/K6L87Ki2GR4jos7tdis1p8RFmV1eDZpGt7dWBglUmWd13bqKMmK8pDnBdF8QWDZB6VH1Pv1nyKfTZpGktAPQoTWk4t1t+xewX0l0kmckKdrsnqeRSkvxnHnWJaNkmVrtruDLXe2kMrqbjVpfiy4j6ysWMcJse3IvCwrgjBG7xsY5hDFdW3eTy0cx8VxZ0wtG8t2CMJIqrm1fd9+skx5+uSpjLX1ekxxssR2XP4DzzEMzX3kt+IAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/79a3d7c3a1b3ca49434b9eaaa9eba99b/e4706/Lense_16mm_Angle_of_View.avif 230w", "/en/static/79a3d7c3a1b3ca49434b9eaaa9eba99b/d1af7/Lense_16mm_Angle_of_View.avif 460w", "/en/static/79a3d7c3a1b3ca49434b9eaaa9eba99b/5e9eb/Lense_16mm_Angle_of_View.avif 642w"],
              "sizes": "(max-width: 642px) 100vw, 642px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/79a3d7c3a1b3ca49434b9eaaa9eba99b/a0b58/Lense_16mm_Angle_of_View.webp 230w", "/en/static/79a3d7c3a1b3ca49434b9eaaa9eba99b/bc10c/Lense_16mm_Angle_of_View.webp 460w", "/en/static/79a3d7c3a1b3ca49434b9eaaa9eba99b/7eb86/Lense_16mm_Angle_of_View.webp 642w"],
              "sizes": "(max-width: 642px) 100vw, 642px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/79a3d7c3a1b3ca49434b9eaaa9eba99b/81c8e/Lense_16mm_Angle_of_View.png 230w", "/en/static/79a3d7c3a1b3ca49434b9eaaa9eba99b/08a84/Lense_16mm_Angle_of_View.png 460w", "/en/static/79a3d7c3a1b3ca49434b9eaaa9eba99b/1bba8/Lense_16mm_Angle_of_View.png 642w"],
              "sizes": "(max-width: 642px) 100vw, 642px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/79a3d7c3a1b3ca49434b9eaaa9eba99b/1bba8/Lense_16mm_Angle_of_View.png",
              "alt": "IN-1600 Tele",
              "title": "IN-1600 Tele",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The angle of view of the 16.0mm lense. Field Angle (D x H x V): 18.0° x 14.5° x 11.2°`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b98a478067172c23f0693f19b5a841ca/6af66/Tele_Lense_16mm_IRcut.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAADnklEQVQozwGTA2z8AIGVlYWkklt8YkdSUFNcXD1RRyctKSMoJzI3MlZWTh0iHDI0MGpxYEtdREZHQ5KhfGeDdGmVhoC3lm+oggBGYFI3U0FQdFdMX1JUX1tPa1g/T0IbHx0wNi9XV04aIBc8PzhHSUAoLSd5eXS4z62FrXqItpuUyKGe1aMAJzgxKTwzN1A9Pk9DYWpkWHFeU21dISkiLTIsZWRcJSghQEE6MDcuNTcxs7GexOLWdqx5hr6Ng7yNnd2cACk6My5BNjxQQkBPS19qZFdwX2qLgDZEPCksJ5GPhD9DOEVEPUBBNnRuYN7o093u55O5pW2jdXWuem6uawBJVlRgaWiUmpxobnNibmU3UDhPbltHXlIuMCinmIRmZldcVEdqX06moo6FporT5dXS699CcEodNSgjPDAAU1lcc3l7b354SFNQY21lPVk/Nk86PEw/LjIrnpR/iINyhXhjl4Z0obKZNlk+YIBfmsugRm1TGSoiHi4oAFxhZEtXVDdLPz5IRHZ9d0lkVkxrXTFDNjU5M3hwYZCBb5yMd6+ch67RrURyTSA+JkRrRlR/XSY7Nx0uKgBQWFpJUVNQWFhJUFJudnc0SkFMaVxHY1g3OzdFRTthWEumj3upm4eDrH6IxYh0pXpegWFgiWNKal0dMiwAMkU/Q0tLPU9JRVVQa3VzQ1tIXHRedIx7S1RHMjYtTkc+loFumpiFdaBudaF0gbCEisGKnNGYg7eBKEM1ADtSRzpDQkNXT0FUToOLf2+HYIiacaW3i6ezjKGvjG1xWod7bpGVhzlSPkhkTFZ4XmKOaWuaboa/g01+VAAxPzxAR0s2Ozw/Skh5enRneVaMnneVpH6QmXW3w5u9zJq+tqKeoZM/WkFaeVxjiGlcf2RmjXB/uX9tpHoAJTEzJjEzXm9cVGFXfoR/j6Z6t8qbvc+mnbOIs8GWtcCT4+bIt7eqSFpIUGpUXn5nYHtyaIp4VXxbWHVjAEtWWENWS2B9XVhoVYKIga2+mqCxhs7gu7fGpKm8idPet+LpxL7CrkZYRFRtXWJ/cnWKiJy/r2eEdk1cVgBTWF1gZ2hjbWJNVkt7fXx4jGmImXWsvJHA06m1xZvZ5L/j5s7W3cF1fmxqfmhtjHCFnoeYwqSPwZOZz50Ai46Vpaiuq660lZieo6OmmJ2Ws7qprrOgt76nz9a94OrQ1N2/3OW8ytCsscCfqsSdpMOXqdOit+e6uu68OM6SVLbb5zcAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b98a478067172c23f0693f19b5a841ca/e4706/Tele_Lense_16mm_IRcut.avif 230w", "/en/static/b98a478067172c23f0693f19b5a841ca/d1af7/Tele_Lense_16mm_IRcut.avif 460w", "/en/static/b98a478067172c23f0693f19b5a841ca/e6133/Tele_Lense_16mm_IRcut.avif 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b98a478067172c23f0693f19b5a841ca/a0b58/Tele_Lense_16mm_IRcut.webp 230w", "/en/static/b98a478067172c23f0693f19b5a841ca/bc10c/Tele_Lense_16mm_IRcut.webp 460w", "/en/static/b98a478067172c23f0693f19b5a841ca/0ba47/Tele_Lense_16mm_IRcut.webp 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b98a478067172c23f0693f19b5a841ca/81c8e/Tele_Lense_16mm_IRcut.png 230w", "/en/static/b98a478067172c23f0693f19b5a841ca/08a84/Tele_Lense_16mm_IRcut.png 460w", "/en/static/b98a478067172c23f0693f19b5a841ca/6af66/Tele_Lense_16mm_IRcut.png 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b98a478067172c23f0693f19b5a841ca/6af66/Tele_Lense_16mm_IRcut.png",
              "alt": "IN-1600 Tele",
              "title": "IN-1600 Tele",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Test image of the 16mm tele lense on a 1/5" CMOS sensor with IRcut filter.`}</p>
    <h2 {...{
      "id": "compare-all-lenses",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#compare-all-lenses",
        "aria-label": "compare all lenses permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Compare All Lenses`}</h2>
    <Link to="/Products/Lenses/S-Mount/Objektiv_Vergleich/" mdxType="Link"><EuiButton fill mdxType="EuiButton">Overview</EuiButton></Link>
    <h2 {...{
      "id": "in-1600-tele-lense-chart",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#in-1600-tele-lense-chart",
        "aria-label": "in 1600 tele lense chart permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-1600 Tele Lense Chart`}</h2>
    <p>{`Lense chart for the 16mm for the 1/4" CMOS Sensor (Width/Hight vs. Distance from the Object).`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "727px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2e08c7bc6ae133867e8bda7dae8fbe66/c54b3/Lense_Chart_16mm_Tele.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "65.65217391304347%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABwklEQVQoz31STW/TQBD1/wMhIUr/AIJbKb1HUPg34RIkhARFqMdWassFIaHgJjRJ7cTJ7nrt/fDu7KyRvbWxEOKdVrPzZua9mcha670HANcAnIMkSebzeRzHaZrOZrM4jqfT6WKx6HL+IAKA1e1ytVo4dHVdI2JR5EpJKaW1VmutlBJCaK3ruvbe1y18i0hr/ezo+dMnD09PvwgpOecfP31Ikts+NeQFjtKSMcY5994jYmSMGb9/N3rz6vzsnBDCOVdKaC3/auIc7HYZ57lzDhHvyEop51zdwXdAxC6CnDPKSKsZgvI7spQSAEKfEA0l0DVkrRWlpCwLYzXNN4RljLGiKP7ROcwT3pWpWE5ZTouSEZpSuqkq3f//jyyk2GQpzTPK1jnfGVMNE3pdkRACAHrBAJBt02R9syNJKXio22sJujoLXRSOJBizyZKf19/X2VKqsh9naN4QzZ7BAqLbkfX0+tvNMq6MGno+WJW7uvo6Hr89fHE0Gr08ODi8uLxsyIRls18/Gj/aFSIiAFhjQwfnnLXWGDOZTI6PX9+7/2Dv8f6jvf2Tk8/NhfUTVlXVW8IYy1pst1trbb+CcCQAoJT6DevE3UmWPM6kAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2e08c7bc6ae133867e8bda7dae8fbe66/e4706/Lense_Chart_16mm_Tele.avif 230w", "/en/static/2e08c7bc6ae133867e8bda7dae8fbe66/d1af7/Lense_Chart_16mm_Tele.avif 460w", "/en/static/2e08c7bc6ae133867e8bda7dae8fbe66/c796a/Lense_Chart_16mm_Tele.avif 727w"],
              "sizes": "(max-width: 727px) 100vw, 727px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2e08c7bc6ae133867e8bda7dae8fbe66/a0b58/Lense_Chart_16mm_Tele.webp 230w", "/en/static/2e08c7bc6ae133867e8bda7dae8fbe66/bc10c/Lense_Chart_16mm_Tele.webp 460w", "/en/static/2e08c7bc6ae133867e8bda7dae8fbe66/34975/Lense_Chart_16mm_Tele.webp 727w"],
              "sizes": "(max-width: 727px) 100vw, 727px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2e08c7bc6ae133867e8bda7dae8fbe66/81c8e/Lense_Chart_16mm_Tele.png 230w", "/en/static/2e08c7bc6ae133867e8bda7dae8fbe66/08a84/Lense_Chart_16mm_Tele.png 460w", "/en/static/2e08c7bc6ae133867e8bda7dae8fbe66/c54b3/Lense_Chart_16mm_Tele.png 727w"],
              "sizes": "(max-width: 727px) 100vw, 727px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2e08c7bc6ae133867e8bda7dae8fbe66/c54b3/Lense_Chart_16mm_Tele.png",
              "alt": "IN-1600 Tele",
              "title": "IN-1600 Tele",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Sensing Area:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1/5" CMOS; 5,08mm`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Lense Mount:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`S-Mount`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Focal Length:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16.0 mm (f)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Back Focal Lenght:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`9.38 mm`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Aperture:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2.0`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Iris:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Fixed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Optical Distortion:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`<   -0.3%`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Lense Construction:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3 Glasses (+IR Filter)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Focus Extent:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`20cm - ∞`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Field Angle (D x H x V):`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`18.0° x 14.5° x 11.2°`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Weight:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`6.0 g`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Temperture:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-10°C - 50°C`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      